.rsvp-section {
    background-color: #be8d7a;  /* Light grey background */
    padding: 60px 20px;  /* Spacing around the section */
    text-align: center;  /* Centering content */
    color: #f5f5dc;  /* Darker text color for readability */
    font-family: 'Cinzel', serif;
}

.rsvp-section h2 {
    font-size: 2.5rem;  /* Large heading size */
    color: #f5f5dc;  /* Muted brown for elegance */
    margin-bottom: 20px;
    text-transform: uppercase;  /* All caps for a formal look */
    letter-spacing: 2px;  /* Adds spacing between letters */
}

.rsvp-section p {
    font-size: 1.1rem;  /* Slightly larger paragraph text */
    color: #f5f5dc;  /* Softer text color */
    margin-bottom: 30px;
}

.rsvp-form {
    max-width: 600px;
    margin: 0 auto;
    background-color: #f5f5dc;
    padding: 40px;
    border-radius: 10px;  /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Subtle shadow for depth */
}

.rsvp-form label {
    display: block;
    text-align: left;
    margin-bottom: 10px;
    font-weight: bold;
    color: #be8d7a;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.rsvp-form input,
.rsvp-form select,
.rsvp-form textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;  /* Rounded input fields */
    font-size: 1rem;
    background-color: #fafafa;  /* Soft background color for inputs */
    color: #333;
}

.rsvp-form input:focus,
.rsvp-form select:focus,
.rsvp-form textarea:focus {
    outline: none;
    border-color: #8b5e34;  /* Highlight input fields when focused */
    box-shadow: 0 0 5px rgba(139, 94, 52, 0.5);  /* Slight shadow on focus */
}

.rsvp-form button {
    background-color: #be8d7a;  /* Brown button for a pop of color */
    color: #f5f5dc;
    font-size: 1.1rem;
    padding: 12px 20px;
    border: none;
    border-radius: 50px;  /* Creates pill-shaped button */
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: background-color 0.3s ease;
}

.rsvp-form button:hover {
    background-color: #a67542;  /* Lighter brown on hover */
}

.success-message,
.error-message {
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
}

.change-mind-button {
    background-color: #be8d7a;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.change-mind-button:hover {
    background-color: #a70016;
}