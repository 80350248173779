.hero {
    height: 100vh;
    background-image: url('../../public/hero_background.png'); /* Placeholder image */
    background-size: cover;
    background-position: center 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    color: #f5f5dc;
    background-attachment: scroll;
    background-position: 50% 0;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: inherit;
    background-attachment: fixed;
    background-position: center 0;
    z-index: -1;
    transform: translateZ(0);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
}

.logo {
    margin-bottom: 20px;
}

.logo h1 {
    font-family: 'Cinzel', serif;
    font-size: 76px;
    font-weight: bold;
    margin: 0;
    font-weight: 100;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
}

.hero-content p {
    font-size: 1.5rem;
}

.pill {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 10px 50px;
    border-radius: 50px;
    margin-top: 20px;
    color: #ffffff;
    font-family: 'Cinzel', serif;
    font-size: 1.2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    gap: 20px; /* Space between the two texts */
    font-family: 'Cinzel', serif;
    font-size: 18px;
}

/* Responsive styles for mobile screens */
@media only screen and (max-width: 768px) {
    .logo h1 {
        font-size: 48px; /* Reduce font size for smaller screens */
        transform: scaleX(1);
    }

    .hero-content p {
        font-size: 1.2rem; /* Adjust font size for better readability on smaller screens */
    }

    .pill {
        padding: 8px 30px; /* Reduce padding for mobile */
        font-size: 16px; /* Adjust font size for mobile */
    }

    .hero {
        background-position: left top
    }
}

@media only screen and (max-width: 480px) {
    .logo h1 {
        font-size: 36px; /* Further reduce font size on very small screens */
    }

    .hero-content p {
        font-size: 1rem; /* Further adjust font size for very small screens */
    }

    .pill {
        padding: 6px 20px; /* Further reduce padding for very small screens */
        font-size: 14px; /* Further adjust font size for very small screens */
    }

    .hero {
        padding: 20px 10px;
        background-position: left top;
    }
}
