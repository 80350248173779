body, html {
  margin: 0;
  padding: 0;
  font-family: 'Cinzel', serif;
  scroll-behavior: smooth;
}

.App {
  overflow-x: hidden;
}
