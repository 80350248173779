.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.time-box {
    padding: 15px;
    margin: 0 10px;
    border-radius: 10px;
    text-align: center;
}

.time {
    display: block;
    font-size: 50px;
    color: #ffffff;
    font-family: 'Cinzel', serif;
}

.label {
    display: block;
    font-size: 10px;
    color: #ffffff;
    text-transform: uppercase;
    margin-top: 5px;
    font-family: 'Cinzel', serif;
}
