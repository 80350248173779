/* Style for the Wedding Details section */
.details {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    padding: 20px;
    background-color: #f5f5dc;
    color: #be8d7a;
    text-align: left;
    font-weight: 500;
}

/* Style for the left section (Wedding Details) */
.details-left {
    flex: 1;
    padding: 20px;
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    margin-left: 0; /* Reset margin for mobile */
    margin-right: 10px; /* Reduce margin for mobile */
    border-right: 1px solid #ccc; /* Optional: a thin divider line */
    text-align: center;
}

.details-right {
    flex: 1;
    padding: 20px;
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    margin-left: 10px; /* Reduce margin for mobile */
    margin-right: 0; /* Reset margin for mobile */
}

/* Adjustments for spacing and alignment */
.details-left h2,
.details-right h2 {
    margin-top: 0;
    font-size: 60px;
}

.details-left h3 {
    margin-top: 0;
    font-size: 22px;
    text-align: center;
}

.details-right h3 {
    margin-top: 0;
    font-size: 30px;
    text-align: center;
}

.details-left p {
    margin: 50px 10px;
    font-size: 20px;
}

.details-right p {
    margin: 10px 0;
    font-size: 22px;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .details {
        flex-direction: column;
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .details-left,
    .details-right {
        margin: 10px 0; /* Adjust margin for smaller screens */
        border-right: none; /* Remove the border on smaller screens */
    }

    .details-left h2,
    .details-right h2 {
        font-size: 40px; /* Reduce font size for smaller screens */
    }

    .details-left h3,
    .details-right h3 {
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    .details-left p,
    .details-right p {
        font-size: 16px; /* Adjust font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .details-left h2,
    .details-right h2 {
        font-size: 30px; /* Further reduce font size for very small screens */
    }

    .details-left h3,
    .details-right h3 {
        font-size: 16px; /* Further adjust font size for very small screens */
    }

    .details-left p,
    .details-right p {
        font-size: 14px; /* Further adjust font size for very small screens */
    }
}
